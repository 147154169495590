const configs = {
    local: {
        api: {
            url: 'http://localhost:3001',
        },
    },
    us: {
        api: {
            url: 'https://api.teeta.us',
        },
    },
    ca: {
        api: {
            url: 'https://api.teeta.ca',
        },
    },
    com: {
        api: {
            url: 'https://api.teeta.com',
        },
    },
}

const env = (process.env.REACT_APP_ENV as keyof typeof configs) || 'local';

export const config = configs[env];

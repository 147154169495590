import { useCallback, useState, useEffect, useRef } from 'react';
import { Button, Input, Space, Typography } from 'antd';
import jwtDecode from 'jwt-decode';

import './App.css';
import ask from './ask';
import { config } from './config';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/vs2015.css';
import { v4 as uuid } from 'uuid';

import Logo from './title.png';
import Title from './chatwithteeta.png';

const { Text } = Typography;

const enum Role {
  System = 'system',
  Assistant = 'assistant',
  User = 'user',
}

export interface Message {
  role: Role;
  content: string;
}

interface Log extends Message {
  id: string;
  answering?: boolean;
  sender: Role;
}

export default () => {
  const conversationId = uuid();
  const tokenRef = useRef<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [accessCode, setAccessCode] = useState<string>('');

  useEffect(() => {
    const storedToken = localStorage.getItem('jwt');
    if (!storedToken) return;
    const decodedToken: any = jwtDecode(storedToken);
    const currentTime = Date.now().valueOf() / 1000;
    
    if (decodedToken.hasOwnProperty('exp') && decodedToken.exp > currentTime) {
      // Token is not expired
      setToken(storedToken);
      tokenRef.current = storedToken;
    } else {
      // Token is expired
      localStorage.removeItem('jwt');
    }
  }, []);

  const handleAccessCodeSubmit = async () => {
    try {
      const response = await fetch(`${config.api.url}/access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessCode: accessCode,
        }),
      });

      const data = await response.json();
      setToken(data.token);
      tokenRef.current = data.token;
      localStorage.setItem('jwt', data.token);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [question, setQuestion] = useState('');
  const [logs, setLogs] = useState<Log[]>(() => [
    {
      id: crypto.randomUUID(),
      role: Role.System,
      content: 'You are "Teeta", a helpful assistant that specializes in helping users with shopping decisions. '
      + 'You have extensive knowledge about different products, their features, '
      + 'and can provide advice to help the user make the best purchase decision.'
      + 'You always look at customer reviews for the product user looking for, and ask proper questions'
      + 'You always try to provide answers concise and straightforward'
      + 'You always try to provide purchase links'
      + 'You ask customer feedback',
      sender: Role.Assistant,
    }
  ]);

  const [answeringContent, setAnsweringContent] = useState('');

  const save = async (question: string, answer: string) => {
    try {
      await fetch(`${config.api.url}/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: tokenRef.current || '',
        },
        body: JSON.stringify({
          conversationId,
          question,
          answer,
        }),
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const askQuestion = useCallback((messages: Message[]) => {
    setAnsweringContent('&ZeroWidthSpace;');

    let contents = '';
    ask((str) => {
      if (!str) return;
      if (str === '[DONE]') {
        void save(messages[messages.length - 1].content, contents)
        setAnsweringContent('');
        setLogs((prev) =>
          prev.map((i) => {
            const { answering, ...rest } = i;
            if (answering) {
              return {
                ...rest,
                content: contents,
              };
            }
            return i;
          })
        );

        return;
      }

      let content = str;
      try {
        const data = JSON.parse(str);
        content = data.choices?.reduce((acc: string, cur: unknown) => {
          // @ts-expect-error
          acc += cur?.delta?.content ?? '';
          return acc;
        }, '');
      } catch {
        // Ignore
        // console.error(err);
      }

      contents += content;
      setAnsweringContent(contents);
    }, messages, tokenRef.current || '');
  }, []);

  const isAnswering = Boolean(answeringContent);

  const onSubmit = () => {
    if (isAnswering || !question) return;
    const messages = logs.concat({
      id: crypto.randomUUID(),
      role: Role.User,
      content: question,
      sender: Role.User,
    });

    askQuestion(messages.map((i) => ({ role: i.role, content: i.content })));
    setQuestion('');
    setLogs([
      ...messages,
      {
        content: '',
        answering: true,
        role: Role.Assistant,
        id: crypto.randomUUID(),
        sender: Role.Assistant,
      },
    ]);
  };

  return (
    <main className='main' style={{minHeight: 'calc(100vh - 60px)', paddingBottom: '60px'}}>


      {!token ? (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // This will make the div take up the full height of the view
        }}>
            <img 
                src={Logo}
                alt="TeeTa - Shopping Simplified"
                style={{
                  width: '300px',
                  fill: '#5358a9',
                  marginBottom: '20px'
                }}
            />
            <input
                style={{
                  marginBottom: '10px', 
                  padding: '10px', 
                  border: '2px solid #5358a9', 
                  borderRadius: '5px', 
                  outline: 'none'
                }}
                type="text"
                value={accessCode}
                onChange={e => setAccessCode(e.target.value)}
                placeholder="Access code"
            />
            <button
                onClick={handleAccessCodeSubmit}
                style={{
                  backgroundColor: '#5358a9', 
                  color: 'white', 
                  border: 'none', 
                  padding: '10px 20px', 
                  borderRadius: '5px', 
                  cursor: 'pointer', 
                  fontSize: '1rem'
                }}>Get Started
            </button>
        </div>
      ) : (
        <div className='chat'>

<div className='title'>
    <img src={Title} alt="TeeTa - Your smart personal shopping assistant" />
  </div>

          <div className='logs' style={{alignItems: 'flex-start'}}>
            {logs.map((l, index) => (
              <div key={l.id} className='log' style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={l.sender === Role.Assistant ? 'bot-response chat-bubble-left' : 'customer-input chat-bubble-right'}>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeHighlight]}
                  components={{
                    // Use a custom renderer for links
                    a: ({node, ...props}) => <a {...props} target="_blank" rel="noopener noreferrer" />
                  }}
                >
                {l.answering
                    ? answeringContent
                    : (index === 0
                      ? 'Welcome to Teeta, your personal shopping assistant! How can I assist you today? Are you looking for something specific?'
                      : l.content)}
                </ReactMarkdown>
              </div>
            </div>
            ))}
          </div>
          <Space.Compact>
            <Input
              allowClear
              value={question}
              onPressEnter={onSubmit}
              placeholder='Enter your question'
              onChange={(e) => setQuestion(e.target.value)}
            />
            <Button type='primary' onClick={onSubmit} loading={isAnswering}>
              {isAnswering ? 'Answering' : 'Submit'}
            </Button>
          </Space.Compact>
        </div>
      )}
      <footer style={{
        width: '100%', 
        position: 'fixed',
        bottom: '0', 
        textAlign: 'center', 
        backgroundColor: '#f8f9fa', 
        padding: '0px 30px 10px 0px',
        fontSize: '0.7rem',
        boxSizing: 'border-box'
      }}>
        Disclaimer: TeeTa.com is built with generative AI. We disclaim any liability for errors, omissions, or inaccuracies in our service's responses. By using our service, you agree to these terms.
      </footer>
    </main>
  );
};